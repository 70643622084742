import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
    apiKey: "AIzaSyDcNeXQT00gyNgejyX8hv1DzXb8X6QvrQM",
    authDomain: "mentorkart-indonesia.firebaseapp.com",
    databaseURL: "https://mentorkart-indonesia.firebaseio.com",
    projectId: "mentorkart-indonesia",
    storageBucket: "mentorkart-indonesia.appspot.com",
    messagingSenderId: "712001428667",
    appId: "1:712001428667:web:04972e57f72bbc0e92dd9e",
    measurementId: "G-4KSV4RSB4E"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
Notification.requestPermission()
    .then(function () {
        console.log('Notification permission granted.');
    })
    .catch(function (err) {
        console.log('Unable to get permission to notify.', err);
    });

getToken(messaging)
    .then(function (currentToken) {
        if (currentToken) {
            console.log('Token received: ', currentToken);
            localStorage.setItem("Vap_add", currentToken)
            var url = `${process.env.REACT_APP_BASE_URL}/notify-register`;

            var data = {
                'identity': JSON.parse(localStorage.getItem("userData")).user.email,
                'address': currentToken,
                'binding_type': 'fcm'
            }
            // postRequest(url, data);
        } else {
            console.log('No Instance ID token available. Request permission to generate one.');
        }
    })
    .catch(function (err) {
        console.log('An error occurred while retrieving token. ', err);
    });

onMessage(messaging, function (payload) {
    console.log('Message received. ', payload);
});






function postRequest(url, data) {
    console.log(data)
    var XHR = new XMLHttpRequest();
    var urlEncodedData = "";
    var urlEncodedDataPairs = [];
    var name;

    // Turn the data object into an array of URL-encoded key/value pairs.
    for (name in data) {
        urlEncodedDataPairs.push(encodeURIComponent(name) + '=' + encodeURIComponent(data[name]));
    }

    // Combine the pairs into a single string and replace all %-encoded spaces to 
    // the '+' character; matches the behaviour of browser form submissions.
    urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

    // Define what happens on successful data submission
    XHR.addEventListener('load', function (event) {
        //console.log(XHR.responseText);
        // alert(XHR.responseText);
    });

    // Define what happens in case of error
    XHR.addEventListener('error', function (event) {
        // alert('ERROR:' + XHR.responseText);
    });

    // Set up our request
    XHR.open('POST', url);

    // Add the required HTTP header for form data POST requests
    XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    XHR.setRequestHeader('Authorization', localStorage.getItem("Mtoken"));
    // Finally, send our data.
    XHR.send(urlEncodedData);
}

